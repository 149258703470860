import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import './theme/index.css'
Vue.use(ElementUI)
// import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

// import './assets/font/fontsize/iconfont.css'
import './style/index.scss' // global css
import local from './util/local'
Vue.prototype.$local = local
import util from './util/util'
Vue.prototype.$util = util

import api from '@/api/api'
Vue.prototype.$api = api
import axios from 'axios'
Vue.prototype.$axios = axios

// let basUrl = 'http://api.tomaphin.com/' // 测试
// Vue.prototype.$PayNameImg= 'heng';
// let PayName = '测试代理'

// let basUrl = 'http://api.huiyunjj.cn' // heng
// let basUrl = 'http://api.hengzf.net' // heng
// Vue.prototype.$PayNameImg= 'heng';
// let PayName = '恒代理'

// let basUrl = 'http://xapi.fivedoves.net' // laike
// Vue.prototype.$PayNameImg= 'laike';
// let PayName = '莱克代理'

// let basUrl = 'http://siapi.trzf66.com' // 天荣
// Vue.prototype.$PayNameImg= 'tianrong';
// let PayName = '天荣d'

// let basUrl = 'http://jdsapi.stndevelop.com' // 金东
// Vue.prototype.$PayNameImg= 'jindong';
// let PayName = '金东国际d'

// let basUrl = 'http://alisapi.absinboxer.com' // 智云
// Vue.prototype.$PayNameImg= 'zhiyun';
// let PayName = '智云d'

// let basUrl = 'http://api.kebangle.xyz' // 万鼎
// Vue.prototype.$PayNameImg= 'wanding';
// let PayName = '万鼎d'

// let basUrl = 'http://jdsapi.stndevelop.com' // 恒远
// Vue.prototype.$PayNameImg= 'hengyuan';
// let PayName = '恒远d'

// let basUrl = 'http://hysapi.vesseler.net' // 腾飞
// Vue.prototype.$PayNameImg= 'tenfei';
// let PayName = '腾飞ag'

// let basUrl = 'http://slsapi.headaski.com' // 胜利
// Vue.prototype.$PayNameImg= 'shengli';
// let PayName = '胜利d'

// let basUrl = 'http://sxsapi.synecoll.com' // 双鑫
// Vue.prototype.$PayNameImg= 'shuangxin';
// let PayName = '双鑫ag'

let basUrl = 'http://api.jiutianea.com' // 777
Vue.prototype.$PayNameImg= '777';
let PayName = '柒星-代理后台'

// let basUrl = 'http://api.leicafav.com' // 发财树
// Vue.prototype.$PayNameImg= 'facaishu';
// let PayName = '发财树Ag'

// let basUrl = 'http://sapi.fridawoods.com' // 众航
// Vue.prototype.$PayNameImg= 'zhonghang';
// let PayName = '众航Ag'

// let basUrl = 'http://sxsapi.synecoll.com' // 三国
// Vue.prototype.$PayNameImg= 'sanguo';
// let PayName = '三国Ag'

// let basUrl = 'http://api.absinboxer.com' // 兴茂
// Vue.prototype.$PayNameImg= 'xingmao';
// let PayName = '兴茂Ag'

Vue.prototype.$baseURL= basUrl;
import md5 from 'js-md5';
Vue.prototype.$md5= md5;
import QRCode from 'qrcodejs2'
Vue.prototype.$QRCode= QRCode;

let hasSide = 'true'
Vue.prototype.$hasSide = hasSide
Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
	document.title = PayName
	next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
