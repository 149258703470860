import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main.vue'
import Login from '../views/login/index.vue'
import Directory from '../views/index.vue' 

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Main',
		component: Main,
		directory:false
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		directory:false,
		meta: {
			noNav:true
		},
	},
	{
		path: '/replace',
		name: 'Replace',
		component: ()=>import('@/views/replace.vue'),
		directory:false,
		meta: {
			noNav:true
		},
	},
	{
		path: '/tongji',
		name: 'v',
		component: Directory,
		
		meta: {
			id:1,
			title: '统计',
			allShow:false,
			leven:0,
			parentId:0,
		},
		directory:true
	},
	{
		path: '/home',
		name: 'Home',
		component: ()=>import('@/views/home.vue'),
		meta: {
			id:3,
			title: '余额总览',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	{
		path: '/exchangeRate',
		name: 'exchangeRate',
		component: ()=>import('@/views/exchangeRate.vue'),
		meta: {
			id:3,
			title: '汇率',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	{
		path: '/merchants',
		name: 'merchants',
		component: ()=>import('@/views/merchants.vue'),
		meta: {
			id:3,
			title: '下属商户',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	{
		path: '/payment',
		name: 'Payment',
		component: Directory,
		meta: {
			id:2,
			title: '代付菜单',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:true
	},
	{
		path: '/realTimePayment',
		name: 'RealTimePayment',
		component: () => import('@/views/payment/index.vue'),
		meta: {
			id:3,
			title: '代理提现',
			allShow:false,
			leven:1,
			parentId:2,
			islink:true
		},
		directory:false
	},
	{
		path: '/paymentRecord',
		name: 'PaymentRecord',
		component: () => import('@/views/payment/paymentRecord.vue'),
		meta: {
			id:3,
			title: '代付记录',
			allShow:false,
			leven:1,
			parentId:2,
			islink:true
		},
		directory:false
	},
	/* {
		path: '/bankList',
		name: 'BankList',
		component: () => import('@/views/payment/bankList.vue'),
		meta: {
			id:4,
			title: '银行卡列表',
			allShow:false,
			leven:1,
			parentId:2,
			islink:true
		},
		directory:false
	}, */
	{
		path: '/transaction',
		name: 'Transaction',
		component: Directory,
		meta: {
			id:5,
			title: '交易菜单',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:true
	},
	{
		path: '/transactionOrder',
		name: 'TransactionOrder',
		component: () => import('@/views/transaction/index.vue'),
		meta: {
			id:6,
			title: '交易订单',
			allShow:false,
			leven:1,
			parentId:5,
			islink:true
		},
		directory:true
	},
	{
		path: '/transactionOfflineOrder',
		name: 'transactionOfflineOrder',
		component: () => import('@/views/transaction/transactionOfflineOrder.vue'),
		meta: {
			id:12,
			title: '商户入金订单记录',
			allShow:false,
			leven:1,
			parentId:5,
			islink:true
		},
		directory:true
	},
	{
		path: '/transactionReport',
		name: 'TransactionReport',
		component: () => import('@/views/transaction/report.vue'),
		meta: {
			id:7,
			title: '交易报表',
			allShow:false,
			leven:1,
			parentId:5,
			islink:true
		},
		directory:true
	},
	
	{
		path: '/traderInfo',
		name: 'TraderInfo',
		component: Directory,
		meta: {
			id:8,
			title: '商户信息菜单',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:false
	},
	{
		path: '/keySet',
		name: 'KeySet',
		component: () => import('@/views/traderInfo/index.vue'),
		meta: {
			id:9,
			title: '密钥设置',
			allShow:false,
			leven:1,
			parentId:8,
			islink:true
		},
		directory:false
	},
	{
		path: '/Tirun',
		name: 'Tirun',
		component: () => import('@/views/payment/tirun'),
		meta: {
			id:10,
			title: '提润',
			allShow:false,
			leven:1,
			parentId:2,			
			islink:true
		},
		directory:false
	},
	{
		path: '/tirunOrder',
		name: 'TirunOrder',
		component: () => import('@/views/payment/tirunOrder'),
		meta: {
			id:11,
			title: '提润记录',
			allShow:false,
			leven:1,
			parentId:2,			
			islink:true
		},
		directory:true
	}, /**/
	{
		path: '/traderPaymentOrder',
		name: 'traderPaymentOrder',
		component: () => import('@/views/payment/traderPaymentOrder'),
		meta: {
			id:13,
			title: '代付记录',
			allShow:false,
			leven:1,
			parentId:2,			
			islink:true
		},
		directory:true
	}, /**/
	{
		path: '/googleVerification',
		name: 'GoogleVerification',
		component: ()=>import('@/views/login/googleVerification.vue'),
		directory:false,
		meta: {
			noNav:true
		},
	},
]

const router = new VueRouter({
	linkActiveClass: "activeClass",
	/*添加默认样式 activeClass   activeClass 写在组件样式里面   */
	routes
})

export default router
