import axios from 'axios'
import local from './local'
import {
	Message
} from 'element-ui'
// import store from '@/store'
// import {getToken} from '@/utils/auth'
const urlArr = [];
// console.log(this.$baseURL)
// create an axios instance

const service = axios.create({
     // baseURL: 'http://api.tomaphin.com/', // 测试
     // baseURL: 'http://xapi.comeonmygrandpa.com', // 测试
     // baseURL: 'http://api.huiyunjj.cn', // heng
     // baseURL: 'http://api.hengzf.net', // heng
     // baseURL: 'http://sapi.fridawoods.com', // YL
     // baseURL: 'http://jdsapi.stndevelop.com', // 金东
     // baseURL: 'http://alisapi.absinboxer.com', // 智云
     // baseURL: 'http://jdsapi.stndevelop.com', // 恒远
     // baseURL: 'http://hysapi.vesseler.net', // 腾飞
     // baseURL: 'http://sxsapi.synecoll.com', // 双鑫
     baseURL: 'http://api.jiutianea.com', // 柒星
     // baseURL: 'http://api.leicafav.com', // 发财树
     // baseURL: 'http://sapi.fridawoods.com', // 众航
     // baseURL: 'http://sxsapi.synecoll.com', // 三国
     // baseURL: 'http://api.absinboxer.com', // 兴茂

	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 30000, // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		let data = {
			username: local.get('dlusername'),
			tid: local.get('dltid')
		}
		// config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
		// if(data.tid && data.username){			
		// 	config.data = Object.assign(config.data, data);
		// }
		// 	return config


		// do something before request is sent
		// console.log('==dd==', config)
		let fullUrl = config.baseURL + config.url
		console.log(fullUrl)
		if (config.allowRepetition) { //允许重复请求数据的接口
			if (config.url != '/agent/login/logout' && config.url != '/agent/login/doLogin' && config.url != '/agent/login/validateGcode' && config.url != '/agent/login/bindGoogle') {

				if (data.tid && data.username) {
					config.data = Object.assign(config.data, data);
				}else{
					window.location.href = '/'
					return Promise.reject({
						message: '账号未登录，请重新登录'
					})
				}
			}
			return config

		} else if (urlArr.indexOf(fullUrl) === -1) { //不允许重复请求数据的接口

			urlArr.push(fullUrl);
			setTimeout(() => {
				urlArr.splice(urlArr.indexOf(fullUrl), 1)
			}, 1000)

			if (config.url != '/agent/login/logout' && config.url != '/agent/login/doLogin' && config.url != '/agent/login/validateGcode' && config.url != '/agent/login/bindGoogle') {
				if (data.tid && data.username) {
					config.data = Object.assign(config.data, data);
				}else{
					window.location.href = '/'
					return Promise.reject({
						message: '账号未登录，请重新登录'
					})
				}
			}
			return config
		} else {
			return Promise.reject({
				message: '重复请求'
			})
			// return
		}
	},
	error => {
		// do something with request error
		// console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data
		// return res
		// console.log(response.request.responseURL)
		let fullUrl = response.request.responseURL
		urlArr.splice(urlArr.indexOf(fullUrl), 1)
		// 错误码配置
		if (res.status != 1 && res.status != 3) {
			Message({
				message: res.msg || 'Error',
				type: 'error',
				duration: 5 * 1000
			})
			if (res.status === 2) {
				// console.log('ddd')
				// store.dispatch('user/resetToken')	
				local.remove('dltid')
				local.remove('dlusername')
				window.location.href = '/'
				return
			}
			return Promise.reject(new Error(res.msg || 'Error'))
		} else {
			return res
		}
	},
	error => {
		// console.log('err' + error) // for debug
		Message({
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)

export default service
